import { Theme } from "theme-ui";

export const theme: Theme = {
  breakpoints: ["64em", "90.06em"],
  space: [
    "0rem", // 0px
    "0.25rem", // 4px
    "0.5rem", // 8px
    "1rem", // 16px
    "2rem", // 32px
    "4rem", // 64px
    "8rem", // 128px
    "16rem", // 256px
    "32rem", // 512px
  ],
  zIndices: {
    back: -1,
    normal: 0,
    front: 1,
  },
  fontSizes: [
    "0.88rem", // 14px
    "1rem", // 16px
    "1.1rem", // 17.5px
    "1.33rem", // 21.33px
    "1.74rem", // 27.88px
    "1.78rem", // 28.43px
    "2.14rem", // 34.18px
    "2.37rem", // 37.9px
    "2.67rem", // 42.72px
    "3.16rem", // 50.52px
    "4.21rem", // 67.34px
  ],
  fonts: {
    heading: "Saira",
    body: "Lato",
  },
  colors: {
    themeTeal: "#66D6C0",
    themeBlue: "#1A8FAB",
    themeDarkPurple: "#431A63",
    themePurple: "#6F4DB1",
    themeOrange: "#FFA53E",
    themeBlack: "#1C1C1C",
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: 600,
      letterSpacing: "0.05rem",
    },
    body: {
      fontFamily: "body",
      fontWeight: 400,
      fontSize: [0, 1],
    },
  },
  layout: {
    container1200: {
      maxWidth: "1232px",
      px: 3,
    },
    container1000: {
      maxWidth: "1032px",
      px: 3,
    },
    container800: {
      maxWidth: "832px",
      px: 3,
    },
    homepageRightSideImage: {
      maxWidth: ["1352px", null, "calc((100vw - 1440px) / 2 + 1352px)"],
      pl: 3,
      mr: 0,
    },
    homepageLeftSideImage: {
      maxWidth: ["1352px", null, "calc((100vw - 1440px) / 2 + 1352px)"],
      pr: 3,
      ml: 0,
    },
    mobileHeader: {
      width: "100%",
      px: 4,
    },
  },
  links: {
    nav: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: 1,
      textDecoration: "none",
      cursor: "pointer",
      transform: "color 0.3s ease-in-out",
      "&:hover, &:active": {
        color: "themeBlue",
      },
    },
    mobileNav: {
      variant: "links.nav",
      fontSize: "20px",
      color: "white",
    },
    arrow: {
      fontFamily: "heading",
      fontWeight: "semiBold",
      fontSize: [2, 3],
      color: "themeBlack",
      textDecoration: "none",
      cursor: "pointer",
      "::after": {
        content: `">"`,
        px: 2,
      },
    },
    arrowLowerCaseText: {
      fontFamily: "body",
      fontWeight: "body",
      fontSize: [0],
      color: "themeBlack",
      textDecoration: "none",
      "&:hover": {
        cursor: "pointer",
      },
      "::after": {
        content: `">"`,
        px: 2,
      },
    },
    infoEmail: {
      fontFamily: "heading",
      fontSize: 3,
      fontWeight: 600,
      color: "themeBlack",
      textDecoration: "none",
      ".iconWrapper": {
        mr: 2,
      },
    },
    serviceCard: {
      textDecoration: "none",
      color: "themeBlack",
    },
    download: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: [2, 3],
      // textDecoration: "none",
      color: "themeBlue",
      cursor: "pointer",
    },
    footer: {
      fontFamily: "body",
      fontWeight: 400,
      fontSize: 1,
      color: "themeBlack",
      textDecoration: "none",
      cursor: "pointer",
    },
  },
  buttons: {
    pagination: {
      p: 0,
      fontFamily: "heading",
      fontSize: 3,
      fontWeight: 600,
      bg: "transparent",
      color: "themeBlack",
      cursor: "pointer",
    },
    paginationActive: {
      variant: "buttons.pagination",
      fontStyle: "italic",
      textDecoration: "underline",
      color: "themeBlue",
    },
    submit: {
      width: "102px",
      height: "40px",
      p: "0px",
      fontFamily: "body",
      fontWeight: "body",
      fontSize: [0],
      color: "themeBlack",
      border: "2px solid",
      borderRadius: "5px",
      cursor: "pointer",
    },
    learnMore: {
      p: 0,
      fontFamily: "heading",
      fontWeight: "semiBold",
      fontSize: [2, 3],
      color: "themeBlack",
      textDecoration: "none",
      cursor: "pointer",
      bg: "transparent",
      borderRadius: 0,
      "::after": {
        content: '">"',
        ml: 2,
      },
    },
    icon: {
      p: 0,
      bg: "transparent",
      borderRadius: 0,
      cursor: "pointer",
    },
  },
  forms: {
    contactForm: {
      width: "100%",
      maxWidth: "488px",
    },
    input: {
      height: "40px",
      my: 2,
      fontFamily: "body",
      fontWeight: "body",
      fontSize: 1,
      color: "themeBlack",
      border: "2px solid",
      borderColor: "themeBlack",
      borderRadius: "5px",
      ":focus": {
        outline: "none",
        borderColor: "themeTeal",
      },
    },
    textarea: {
      height: "99px",
      my: 2,
      fontFamily: "body",
      fontWeight: "body",
      fontSize: 1,
      color: "themeBlack",
      border: "2px solid",
      borderColor: "themeBlack",
      borderRadius: "5px",
      resize: "none",
      ":focus": {
        outline: "none",
        borderColor: "themeTeal",
      },
    },
  },
  cards: {
    caseStudy: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "themeBlack",
      ".thumbnail": {
        width: ["382px", "475px"],
        height: ["385px", "479px"],
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "40px",
        },
      },
      ".content": {
        width: ["342px", "582px"],
        height: ["375px", "395px"],
        p: [3, 4],
        position: "relative",
        zIndex: "front",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        bg: "#F4F4F4",
        "> p": {
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
    },
    service: {
      width: ["157px", "184px"],
      height: ["157px", "184px"],
      px: 3,
      pt: 4,
      pb: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      bg: "#E1E1E1",
      borderRadius: "25px",
    },
    moveYourBrand: {
      // width: "100%",
      maxWidth: ["380px", "1200px"],
      px: [4, 6],
      py: [4, 5],
      display: "flex",
      flexDirection: ["column", "row"],
      justifyContent: "space-between",
      alignItems: "center",
      color: "white",
      bg: "themeOrange",
      borderRadius: "30px",
      columnLeft: {},
      columnRight: {},
    },
    employee: {
      width: ["178px", "285px"],
      height: ["178px", "285px"],
      px: 3,
      py: 2,
      mb: ["36px", "50px"],
      color: "white",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      boxShadow: "0 0 20px rgb(0 0 0 / 0.15)",
      borderRadius: "25px",
      ".headshotImageWrapper": {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -1,
        ".gradientOverlay": {
          width: "100%",
          height: "100%",
          position: "absolute",
          borderRadius: "25px",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        },
        img: {
          width: "100%",
          height: "100%",
          display: "block",
          borderRadius: "25px",
          objectFit: "cover",
          objectPosition: "center center",
          zIndex: -1,
        },
      },
      ".learnMoreButton": {
        position: "absolute",
        bottom: ["-36px", "-50px"],
      },
    },
    employeeModal: {
      width: ["300px", "673px"],
      pl: 4,
      pr: [3, 5],
      py: 4,
      bg: "white",
      color: "themeBlack",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "14px",
      ".headshotImageWrapper": {
        width: "72px",
        height: "72px",
        position: "relative",
        ".gradientOverlay": {
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: "front",
          borderRadius: "5px",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
        },
        img: {
          width: "72px",
          height: "72px",
          display: "block",
          borderRadius: "5px",
          objectFit: "cover",
          objectPosition: "center center",
        },
      },
      ".details": {
        pr: 2,
      },
    },
  },
  styles: {
    root: {
      "*": {
        boxSizing: "border-box",
      },
      body: {
        p: 0,
        m: 0,
      },
    },
    h1: {
      variant: "text.heading",
      fontWeight: "bold",
      fontSize: [8, 10],
      letterSpacing: "0.1rem",
      textTransform: "uppercase",
    },
    h2: {
      variant: "text.heading",
      fontSize: [6, 9],
    },
    h3: {
      variant: "text.heading",
      fontSize: [4, 7],
    },
    h4: {
      variant: "text.heading",
      fontSize: 3,
    },
    h5: {
      variant: "text.heading",
      fontSize: [2, 3],
    },
    markdown: {
      color: "themeBlack",
      h1: {
        variant: "styles.h1",
        my: 5,
      },
      h2: {
        variant: "styles.h2",
        my: 3,
      },
      h3: {
        variant: "styles.h3",
        my: 3,
      },
      p: {
        variant: "text.body",
        m: 0,
      },
      ul: {
        my: 2,
        fontFamily: "body",
        listStyleType: "disc",
        whiteSpace: "pre-wrap",
      },
      ol: {
        my: 2,
        fontFamily: "body",
        whiteSpace: "pre-wrap",
      },
    },
    modal: {
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 999,
      bg: "rgb(0 0 0 / 0.3)",
    },
    myImage: {
      overflow: "hidden",
      img: {
        width: "100%",
        height: "100%",
        display: "block",
      },
    },
  },
};

export default theme;
